import React, { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'
import moment from 'moment'
import { getGooglePlaceInformation } from 'utils/googlePlace'

import {
  Media,
  BodyRow,
  Attachment,
  DateRange,
  FileCopyIconStyled,
  NoteContainer,
  FileName,
} from './tripLocationItem.style'

type Data = {
  google_place_id: string
  doc: string
  endDate: Date
  endTime: Date
  startDate: Date
  startTime: Date
  googlePhoto: string
  note: string
}

type Props = {
  data: Data
  docURL: string
}

const TripLocationItem = ({ data, docURL }: Props) => {
  const [placeHeroPhoto, setPlaceHeroPhoto] = useState<string | undefined>(
    undefined,
  )
  const { endDate, endTime, startDate, startTime } = data
  let dateSubtitle = ''

  if (!isEmpty(endDate) && !isEmpty(startDate)) {
    const dateIstheSame = moment(startDate).isSame(endDate)

    if (!isEmpty(endTime) && !isEmpty(startTime)) {
      const formattedStartTime = moment(startTime).format('LT')
      const formattedEndTime = moment(endTime).format('LT')

      const formattedStartDate = moment(startDate).format('L')
      const formattedEndDate = moment(endDate).format('L')

      const formattedTime = `${formattedStartTime} - ${formattedEndTime}`
      dateSubtitle = dateIstheSame
        ? `${formattedStartDate} ${formattedTime}`
        : `${formattedStartDate}-${formattedEndDate} ${formattedTime}`
    }
  }
  useEffect(() => {
    if (data && data.google_place_id) {
      getGooglePlaceInformation({
        place_id: data.google_place_id,
        setPlaceHeroPhoto,
      })
    }
  }, [setPlaceHeroPhoto, data])

  return (
    <div>
      <DateRange>{dateSubtitle}</DateRange>
      {data.googlePhoto !== 'null' && (
        <Media src={placeHeroPhoto} alt="destination" />
      )}
      <div>
        {data.note != null && (
          <BodyRow>
            {data.note && (
              <NoteContainer dangerouslySetInnerHTML={{ __html: data.note }} />
            )}
          </BodyRow>
        )}

        <BodyRow>
          {data.doc !== '' && data.doc != null && (
            <Attachment>
              <FileCopyIconStyled />
              <FileName
                href={docURL}
                target="_blank"
                rel="noopener noreferrer"
                download>
                {data.doc.length > 30
                  ? data.doc.substring(0, 30).concat('...')
                  : data.doc}
              </FileName>
            </Attachment>
          )}
        </BodyRow>
      </div>
    </div>
  )
}

export default TripLocationItem

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core'
import { isEmpty, reject } from 'lodash'
import { useAppSelector } from 'store/hooks'

import {
  FiltersContainer,
  FilterOption,
  FilterOptionText,
  CheckBoxInput,
  TagList,
  style,
} from './tags.style'

import './CustomTags.style.scss'
import { ColorDropDown } from '../../components/ColorDropDown'
import { TagDropDown } from '../../components/TagDropDown'
import { Tag } from '../ItemPopup/layouts/PopupTags/components/Tag'
import { colorslist } from '../../components/ColorPalette/ColorPalette'

const useStyles = makeStyles(theme => style(theme))

type DefaultTags = { [tag: string]: boolean }

type Props = {
  onHandleTagsChecked: any
}

const Tags = ({ onHandleTagsChecked }: Props) => {
  const { tripGroups} = useAppSelector(state => state.tripGroupSlice)
  const classes = useStyles()
  const [selectedColors, setSelectedColors] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const tagsArray: string[] = []

  if (tripGroups && tripGroups.length > 0) {
    tripGroups.forEach(tripGroup => {
      tripGroup.tripItems.forEach((item: any) => {
        if(item[`trips_${item.type}s_items_tags`]?.length > 0){
        for (const itemTag of item[`trips_${item.type}s_items_tags`]) {
          if (!tagsArray.includes(itemTag.title)) {
            tagsArray.push(itemTag.title)
          }
        }
      }
      })
    })
  }

  const getColorName = (hex: string) => {
    return colorslist.find(c => c.color === hex)?.label ?? ''
  }

  const customTags = useMemo(() => {
    // remove default tags from incoming tags to avoid duplicates
    const defaultsRemoved = reject(tagsArray, t => {
      return t == 'location' || t == 'note' || t == 'poll'
    })
    defaultsRemoved.sort((a, b) => a.localeCompare(b))
    return defaultsRemoved
  }, [tagsArray])

  const [tagOptions, setTagOptions] = useState(() => {
    const initial: DefaultTags = { location: false, note: false, poll: false }

    // add initial values for customTags to tagOptions
    if (!isEmpty(customTags)) {
      customTags.forEach((tag: string) => {
        if (tag !== 'location' && tag !== 'poll' && tag !== 'note')
          initial[tag] = false
      })
    }
    return initial
  })

  const handleChange = (event: any) => {
    setTagOptionsAndFiltering(event, !tagOptions[event])
  }

  const handleCustomTagSelect = (tagName: any, type: string) => {
    if (type === 'color') {
      setSelectedColors(prevState => [...prevState, tagName])
    } else if (type === 'tag') {
      setSelectedTags(prevState => [...prevState, tagName])
    }
    setTagOptionsAndFiltering(tagName, true)
  }

  const handleCustomTagDeselect = (tagName: any, type: string) => {
    if (type === 'color') {
      setSelectedColors(prevState => [...prevState.filter(c => c !== tagName)])
    } else if (type === 'tag') {
      setSelectedTags(prevState => [...prevState.filter(c => c !== tagName)])
    }
    setTagOptionsAndFiltering(tagName, false)
  }

  const setAllPropertiesToFalse = (obj: any) => {
    const newobj = { ...obj }
    Object.keys(newobj).forEach(key => (newobj[key] = false))
    return newobj
  }

  const setAllColorsToFalse = (obj: any) => {
    const newobj = { ...obj }
    Object.keys(newobj).forEach(key => {
      if (selectedColors.includes(key)) {
        newobj[key] = false
      }
    })
    return newobj
  }

  const setAlltagsToFalse = (obj: any) => {
    const newobj = { ...obj }
    Object.keys(newobj).forEach(key => {
      if (selectedTags.includes(key)) {
        newobj[key] = false
      }
    })
    return newobj
  }

  const setTagOptionsAndFiltering = (event: any, value: boolean) => {
    setTagOptions((prevTagOptions) => { return  {...prevTagOptions, [event]: value }})
    onHandleTagsChecked({
      ...tagOptions,
      [event]: value,
    })
  }

  const clearAllFilters = async () => {
    const updatedTagOptions = setAllPropertiesToFalse({ ...tagOptions })
    setTagOptions({ ...updatedTagOptions })
    onHandleTagsChecked({ ...updatedTagOptions })
    setSelectedColors([])
    setSelectedTags([])
  }

  const removeAllColors = async (e: React.SyntheticEvent) => {
    if (selectedColors.length > 0) {
      e.stopPropagation()
      const updatedTagOptions = setAllColorsToFalse({ ...tagOptions })
      setTagOptions({ ...updatedTagOptions })
      onHandleTagsChecked({ ...updatedTagOptions })
      setSelectedColors([])
    }
  }

  const removeAllTags = async (e: React.SyntheticEvent) => {
    if (selectedTags.length > 0) {
      e.stopPropagation()
      const updatedTagOptions = setAlltagsToFalse({ ...tagOptions })
      setTagOptions({ ...updatedTagOptions })
      onHandleTagsChecked({ ...updatedTagOptions })
      setSelectedTags([])
    }
  }

  return (
    <div style={{ flex: '4', minWidth: '320px', maxWidth: 'calc(100vw - 32px)'}}>
    <FiltersContainer>
      <FilterOption onClick={() => handleChange('note')}>
        <CheckBoxInput
          type="checkbox"
          title="note"
          name="note"
          checked={tagOptions['note']}
        />
        <FilterOptionText>Note</FilterOptionText>
      </FilterOption>

      <FilterOption onClick={() => handleChange('poll')}>
        <CheckBoxInput
          type="checkbox"
          title="poll"
          name="poll"
          checked={tagOptions['poll']}
        />
        <FilterOptionText>Poll</FilterOptionText>
      </FilterOption>

      <FilterOption onClick={() => handleChange('location')}>
        <CheckBoxInput
          type="checkbox"
          title="location"
          name="location"
          checked={tagOptions['location']}
        />
        <FilterOptionText>Location</FilterOptionText>
      </FilterOption>

      <ColorDropDown
        handleCustomTagSelect={handleCustomTagSelect}
        handleCustomTagDeselect={handleCustomTagDeselect}
        selectedColors={selectedColors}
        removeAllColors={removeAllColors}
      />
      <TagDropDown
        handleCustomTagSelect={handleCustomTagSelect}
        handleCustomTagDeselect={handleCustomTagDeselect}
        selectedTags={selectedTags}
        customTags={customTags}
        removeAllTags={removeAllTags}
      />

      <div style={{ width: '100%' }}>
        <button className={classes.clearAllButton} onClick={clearAllFilters}>
          Clear all filters
        </button>
      </div>

      <TagList>
        {selectedTags.map((tag, idx) => {
          return (
            <Tag
              key={idx}
              tag={tag}
              removeTags={() => handleCustomTagDeselect(tag, 'tag')}
            />
          )
        })}
      </TagList>

      <TagList style={{ marginTop: '8px' }}>
        {selectedColors.map((tag, idx) => {
          return (
            <Tag
              key={idx}
              tag={getColorName(tag)}
              displayColor={tag}
              removeTags={() => handleCustomTagDeselect(tag, 'color')}
            />
          )
        })}
      </TagList>
    </FiltersContainer>
    </div>
  )
}

export default Tags

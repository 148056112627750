/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react'

import { HTTPError } from 'ky'
import { PollOptions } from 'pages/Workspace/layouts/PlanningBoard/layouts/PollOptions'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { castVoteOfTripGroupPollItem } from 'store/slices/tripGroupSlice'
import { sendNotification } from 'utils/toast'

import { update } from 'api/tripPollItemOption'
import './styles.scss'
import { LinearProgress } from '@material-ui/core'

type User = {
  firstName: string
  uid: string
}

type Option = {
  title: string
  uid: string
  users: Array<User>
}

type Props = {
  tripItem: any
  pollsOptions: Array<Option>
  tripMembersCount: number
}

const TripPollItem = ({
  tripItem,
  pollsOptions,
  tripMembersCount,
}: Props) => {
  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const dispatch = useAppDispatch()
  /**
   * Handle voting and unvoting on an option
   * @param {Object} option poll option object
   */
  const onHandleOptionVote = async (option: Option) => {
    try {
      const res = await update({
        user_uid: user.uid,
        uid: option.uid,
      })

      if (res.uid === option.uid) {
        let tripGroupOfThatItem: any = {}

        let item: any = {}
        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }

        if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
          for (const groupItem of tripGroupOfThatItem.tripItems) {
            if (groupItem.uid === tripItem.uid) {
              item = groupItem
              break
            }
          }

          const payload = {
            groupUid: tripGroupOfThatItem.uid,
            tripItemUid: item.uid,
            updatedOption: res,
          }

          await dispatch(castVoteOfTripGroupPollItem(payload))
        }

        const voteAction = res?.users.some(
          (u: { uid: string }) => u.uid === user.uid,
        )
          ? 'Voted'
          : 'Unvoted'
        sendNotification(`${voteAction} "${option.title}"`, 'success')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    } finally {
    }
  }

  return (
    <div>
    <PollOptions
      options={pollsOptions}
      onHandleOptionVote={onHandleOptionVote}
      tripMembersCount={tripMembersCount}
      concatTitle={true}
    />
    </div>
  )
}

export default TripPollItem

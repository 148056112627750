import React, { useState, useRef, useEffect } from 'react'

import styled from '@emotion/styled'
import { MenuItem } from '@material-ui/core'
import { MTDialog } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { HTTPError } from 'ky'
import {
  UpdateGridItemProps,
  DeleteGridItemProps,
  GridTripItems,
  TripGroup,
  TripGroupItem,
  LocationTripGroupItem,
  AddGridItemProps
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
//import { dispatch } from 'react-hot-toast/dist/core/store'
import { removeUserOwnedPlacesArray } from 'store/slices/locationSearchResults'
import {
  deleteGroup,
  updateTripGroup,
  unsetNewlyCreatedGroup,
} from 'store/slices/tripGroupSlice'
import { deleteLocation } from 'store/slices/tripItemLocationsMap'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { remove, update as updateGroup } from 'api/tripGroups'

import { HeroMenuButton, MenuStyled, Icon } from '../../../Hero/hero.style'
import { PlanningBoardItem } from '../PlanningBoardItem'
import {
  PlanningBoardGridColumnContainer,
  PlanningBoardItemsList,
} from './planningBoardGridColumn.style'

type Props = {
  index: number
  tripGroup?: TripGroup
  bucket?: string
  tripMembersCount: number
  column: { id: string }
  tripItems: GridTripItems[]
  tripShortUid: string
  disableDragOnGridItems: boolean
  isNewlyCreated: boolean
}

const Wrapper = styled.div`
  // Wrapping Handle, Area
  // display: flex;
  // flex-direction: column;
  // width: 318px;
  // padding-left: 100px;
`
const Handle = styled.div`
  // Handle of board when dragging
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // height: 57px;
  // align-items: center;

  // img {
  //   display: none;
  //   width: 14px;
  //   padding: 5px;
  // }

  // &:hover {
  //   img {
  //     display: block;
  //   }
  // }
`
const Header = styled.div`
  // Head of Board
  // display: flex;
  // flex-direction: row;
  // align-items: center;
`
const GroupTitleDiv = styled.div`
  padding: 4px;
`
const GroupTitleText = styled.h4`
  width: 213px;
  background: #ecf3ff;
  height: 31px;
  padding: 5px;
  border-radius: 5px;

  display: flex;
`

const Text = styled.b`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
`
const GroupTitleInput = styled.input`
  width: 170px;
  //height: 19px;
  //background: #d5e2f7;
  border: 1px solid #acc7f3;
  outline: none;
  border-radius: 4px;
`

const PlanningBoardGridColumn = ({
  index,
  tripGroup,
  bucket,
  column,
  tripMembersCount,
  tripShortUid,
  disableDragOnGridItems,
  isNewlyCreated,
}: Props) => {
  const {
    tripGroups,
    filteredTripGroups,
    newlyCreatedItem,
    newlyCreatedGroup,
  } = useAppSelector(state => state.tripGroupSlice)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)

  const [rerender, setRerender] = useState(false)
  const dispatch = useAppDispatch()

  const [toggle, setToggle] = useState(true)
  const [text, setText] = useState(tripGroup?.title)

  const myRef = useRef<HTMLDivElement>(null)
  const coordinates = useAppSelector(state => state.geoLocation)

  const tabletMobileViewGeneral = tabletMobileScreenSize()

  function toggleInput() {
    setToggle(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(event: any) {
    setText(event.target.value)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function handleBlur() {
    try {
      if (tripGroup && text !== tripGroup.title) {
        const { title, ...rest } = tripGroup
        const data = {
          title: text,
          coordinates,
          ...rest,
        }
        const res = await updateGroup(tripGroup?.uid, data)
        await dispatch(updateTripGroup(data))

        if (res) {
          sendNotification(`${CONSTANTS.TRIP_GROUP_UPDATE_SUCCESS}`, 'success')
        }
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }

    setToggle(!toggle)
  }

  const renderAfterCalled = useRef(false)

  /**
   * Close menu
   */
  const onHandleCloseMenu = () => {
    setMenuAnchorEl(null)
  }
  /**
   * Close delete dialogue
   */
  const onCloseModal = () => {
    setOpenDialog(false)
  }

  /**
   * Show delete modal
   */
  const onHandleShowDeleteTripGroup = () => {
    onHandleCloseMenu()

    // if (!canDeleteTrip)
    //   return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDialog(true)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onCloseModal()
  }

  /**
   * Open menu
   * @param { Object } event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onHandleOpenMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }
  /**
   * Delete trip handler
   */
  const onHandleDeleteTripGroup = async (uid: string | undefined) => {
    if (!renderAfterCalled.current) {
      // your API call func
      try {
        let tripGroupToBeDeleted: TripGroup | null = null
        if (tripGroups && tripGroups.length > 0) {
          for (const group of tripGroups) {
            if (group.uid === uid) {
              tripGroupToBeDeleted = group
              break
            }
          }
        }

        if (
          tripGroupToBeDeleted &&
          tripGroupToBeDeleted.tripItems &&
          tripGroupToBeDeleted.tripItems.length > 0
        ) {
          for (const tripItem of tripGroupToBeDeleted.tripItems) {
            if (tripItem.type === 'location') {
              dispatch(deleteLocation({ uid: tripItem.uid }))
              dispatch(
                removeUserOwnedPlacesArray({
                  place_id: (tripItem as LocationTripGroupItem)
                    ?.google_place_id,
                }),
              )
            }
          }
        }

        let filteredTripGroupToBeDeleted: TripGroup | null = null
        if (filteredTripGroups && filteredTripGroups.length > 0) {
          for (const group of filteredTripGroups) {
            if (group.uid === uid) {
              filteredTripGroupToBeDeleted = group
              break
            }
          }
        }

        if (
          filteredTripGroupToBeDeleted &&
          filteredTripGroupToBeDeleted.tripItems &&
          filteredTripGroupToBeDeleted.tripItems.length > 0
        ) {
          for (const tripItem of filteredTripGroupToBeDeleted.tripItems) {
            if (tripItem.type === 'location') {
              dispatch(deleteLocation({ uid: tripItem.uid }))
              dispatch(
                removeUserOwnedPlacesArray({
                  place_id: (tripItem as LocationTripGroupItem)
                    ?.google_place_id,
                }),
              )
            }
          }
        }
        await dispatch(deleteGroup({ uid })) ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// MAKE IT WORK
        setRerender(!rerender)
        const data = {
          uid,
          coordinates,
        }
        const res = await remove(data)
        // const updatedGroups: Array<TripGroup> = tripGroups.filter(tripGroup => {
        //   if (tripGroup.uid !== uid) return tripGroup
        // })
        if (res) {
          sendNotification(`${CONSTANTS.TRIP_GROUP_DELETE_SUCCESS}`, 'success')
          onCloseModal()
        }
      } catch (error) {
        const { message } = (await error?.response?.json()) || error
        sendNotification(message, 'error')
      }
    }
    onCloseModal()

    renderAfterCalled.current = true
  }

  //const useStyles = makeStyles(() => styles())
  //const classes = useStyles()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getItemStyle = (isDraggingOver: boolean, draggableStyle: any) => ({
    // change background colour if dragging
    transition: ' background 0.2s ease',
    background: isDraggingOver ? '#68a1fc' : '#FAFAFA', //OPCAITY LOGIC HERE

    // styles we need to apply on draggables
    ...draggableStyle,
  })
  /**
   * Open menu
   * @param { Object } event
   */
  // const onHandleOpenMenu = (event: any) => {
  //   setMenuAnchorEl(event.currentTarget)
  // }

  useEffect(() => {
    if (isNewlyCreated && newlyCreatedGroup) {
      myRef.current &&
        myRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      dispatch(unsetNewlyCreatedGroup())
    }
  }, [isNewlyCreated, newlyCreatedGroup, dispatch])

  return (
    <PlanningBoardGridColumnContainer>
      <Draggable
        isDragDisabled={disableDragOnGridItems}
        draggableId={column.id}
        index={index}>
        {provided => (
          <Wrapper ref={provided.innerRef} {...provided.draggableProps}>
            <Handle {...provided.dragHandleProps}>
              <Header
                style={{
                  cursor: disableDragOnGridItems ? 'not-allowed' : 'pointer',
                }}
                ref={myRef}>
                <GroupTitleDiv>
                  <GroupTitleText onDoubleClick={toggleInput}>
                    <span style={{ color: '#8F8F8F' }}>⠿ </span>
                    {toggle ? (
                      <Text>{text}</Text>
                    ) : (
                      <GroupTitleInput
                        autoFocus={!tabletMobileViewGeneral}
                        type="text"
                        value={text}
                        onChange={handleChange}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onKeyPress={(e: any) => {
                          if (e.key === 'Enter') {
                            handleBlur()
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    )}
                    <HeroMenuButton onClick={onHandleOpenMenu}>
                      <Icon />
                    </HeroMenuButton>
                    {/* <HeroMenuButton onClick={onHandleOpenMenu} /> */}
                    <MenuStyled
                      MenuListProps={{
                        style: {
                          textAlign: 'right',
                          padding: 0,
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      disableScrollLock
                      disableRestoreFocus
                      anchorEl={menuAnchorEl}
                      keepMounted
                      open={Boolean(menuAnchorEl)}
                      onClose={onHandleCloseMenu}
                      elevation={3}>
                      <MenuItem onClick={onHandleShowDeleteTripGroup}>
                        Delete Group
                      </MenuItem>
                      {openDialog && (
                        <MTDialog
                          openDialog={openDialog}
                          onCloseModal={onCloseModal}
                          onDeleteTripClickHandler={() =>
                            onHandleDeleteTripGroup(tripGroup?.uid)
                          }
                          onCancelClickHandler={onHandleCancel}
                          itemToBeDeleted={CONSTANTS.TRIP_GROUP_TO_BE_DELETED}
                          type={modalTypes.DELETE_ITEM}
                        />
                      )}
                    </MenuStyled>
                  </GroupTitleText>
                </GroupTitleDiv>
              </Header>
            </Handle>
            <Droppable droppableId={column.id}>
              {(
                { droppableProps, placeholder, innerRef },
                { isDraggingOver },
              ) => (
                <PlanningBoardItemsList
                  {...droppableProps}
                  ref={innerRef}
                  style={getItemStyle(isDraggingOver, droppableProps)}>
                  {tripGroup?.tripItems.map(
                    (tripItem: TripGroupItem, index: number) => (
                      <>
                        <PlanningBoardItem
                          isNewlyCreated={
                            newlyCreatedItem &&
                            newlyCreatedItem.uid === tripItem.uid
                              ? true
                              : false
                          }
                          bucket={bucket}
                          tripMembersCount={tripMembersCount}
                          key={tripItem.id}
                          tripItem={tripItem}
                          index={index}
                          tripShortUid={tripShortUid}
                          disableDragOnGridItems={disableDragOnGridItems}
                        />
                      </>
                    ),
                  )}
                  {placeholder}
                </PlanningBoardItemsList>
              )}
            </Droppable>
          </Wrapper>
        )}
      </Draggable>
    </PlanningBoardGridColumnContainer>
  )
}

export default PlanningBoardGridColumn

import React, { useState } from 'react'

import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { MTTooltip } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import { Formik, Form, FieldArray, Field } from 'formik'
import { SaveButton } from 'pages/Workspace/layouts/PlanningBoard/components/SaveButton'
import {
  AddGridItemProps,
  CurrentTrip,
  TripGroup,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { addItemInTripGroup } from 'store/slices/tripGroupSlice'
import { addGridItemColor } from 'store/slices/tripItemColor'
import { tripItemTypes } from 'utils/itemTypes'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'
import * as Yup from 'yup'

import { create } from 'api/tripPollItem'

import CreateGroupModal from '../../../../../CreateGroupModal/modal'
import {
  Container,
  QuestionBar,
  ErrorsMsg,
  Answer,
  AddAnswerBtn,
  OptionCancelButton,
  SubmitWrapper,
  Body,
  Icon,
} from './pollForm.style'

const PollSchema = Yup.object().shape({
  question: Yup.string()
    .trim()
    .min(2, CONSTANTS.POLL_ITEM_QUESTION_MIN_LENGTH_MESSAGE)
    .max(199, CONSTANTS.POLL_ITEM_QUESTION_MAX_LENGTH_MESSAGE)
    .required(CONSTANTS.POLL_ITEM_QUESTION_REQUIRED_MESSAGE),
})

const GroupDropdown = styled.div`
  margin-left: auto;
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 140px;
  height: 35px;
  margin-top: 9px;
  //margin-right: 5px;
  border: none;
  &:focus {
    outline-width: 0;
  }
`

type Option = {
  title: string
}

type SubmitPollArgs = {
  question: string
  answers: Array<string>
  setSubmitting: (val: boolean) => void
  resetForm: () => void
}

type Props = {
  currentTrip: CurrentTrip
  canEdit: boolean
}

const PollForm = ({ currentTrip, canEdit }: Props) => {
  const { gridOrientation } = useGridOrientation()
  const { setOpenDialog } = useDialog()
  const dispatch = useAppDispatch()
  const [active, setActive] = useState(false)

  const [hover, setHoverIndex] = useState<number | null>(null)
  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const [selectedGroup, setSelectedGroup] = useState<TripGroup | null>()
  const [selectedGroupTitle, setSelectedGroupTitle] = useState('')
  const [loading, setLoading] = useState(false)

  const tabletMobileViewGeneral = tabletMobileScreenSize()

  const toggle = () => setDropdownOpen(prevState => !prevState)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const coordinates = useAppSelector(state => state.geoLocation)

  const handleSubmitPoll = async ({
    question,
    answers,
    setSubmitting,
    resetForm,
  }: SubmitPollArgs) => {
    setLoading(true)
    if (user.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    const options: Array<Option> = []
    answers.forEach(answer => {
      options.push({ title: answer })
    })

    const allGroupItems = selectedGroup?.tripItems
    const lastItemsOrder =
      allGroupItems && allGroupItems.length > 0 ? allGroupItems?.length + 1 : 1

    const formData = {
      title: question,
      poll_options: options,
      short_uid: currentTrip.short_uid,
      user_uid: user.uid,
      trip_group: selectedGroup?.uid,
      grid_orientation: gridOrientation,
      is_tentative: !!user.is_tentative,
      order: lastItemsOrder + 1,
      coordinates,
    }

    try {
      setSubmitting(true)
      const newPoll = await create(formData)
      setSelectedGroup(null)
      setSelectedGroupTitle('')

      const payload = {
        groupUid: selectedGroup?.uid,
        tripItem: newPoll,
      }
      await dispatch(addItemInTripGroup(payload))

      dispatch(
        addGridItemColor({
          uid: newPoll.uid,
          color: newPoll.trip_poll_item_metadata.color,
        }),
      )
      setSubmitting(false)
      sendNotification(`${CONSTANTS.CREATE_NEW_POLL_ITEM}`, 'success')
    } catch (error) {
      setSubmitting(false)
      sendNotification(CONSTANTS.CREATE_NEW_POLL_ITEM_ERROR, 'error')
    } finally {
      resetForm()
      setLoading(false)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomSelectField = (props: any) => {
    return (
      <GroupDropdown>
        <Dropdown
          style={{ width: 'initial' }}
          size={'lg'}
          isOpen={dropdownOpen}
          toggle={toggle}
          direction={'down'}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '35px',
            }}>
            <DropdownToggle
              caret
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '16px',
                width: '100%',
                border: 'none',
                color: '#686868',
                borderRadius: '7px 0px 0px 7px',
                background: '#F9F9F9',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              size="lg">
              {selectedGroup ? selectedGroupTitle : 'Select Group'}
            </DropdownToggle>
          </div>

          <DropdownMenu
            style={{
              marginTop: '6px',
              fontSize: '1.5rem',
              width: '140px',
              overflowX: 'hidden',
              borderRadius: '10px',
              padding: '7px',
            }}>
            {tripGroups.map(tripGroup => {
              return (
                <DropdownItem
                  style={{
                    marginTop: '6px',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    whiteSpace: 'unset',
                    overflowWrap: 'anywhere',
                  }}
                  key={tripGroup.uid}
                  onClick={() => {
                    setSelectedGroup(tripGroup)
                    setSelectedGroupTitle(tripGroup.title)
                    props.setFieldValue(props.formikKey, tripGroup.uid)
                  }}>
                  {tripGroup.title}
                </DropdownItem>
              )
            })}
            <DropdownItem
              style={{
                fontSize: '1.4rem',
                color: '#4287f5',
                fontWeight: 'bold',
                marginTop: '4px',
              }}
              onClick={() => {
                setActive(true)
                setSelectedGroup(null)
                setSelectedGroupTitle('')
                props.setFieldValue(props.formikKey, '')
              }}>
              <ControlPointIcon /> Create Group
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </GroupDropdown>
    )
  }
  return (
    <Container>
      <MTTooltip
        title={!canEdit ? 'You do not have permission to create polls.' : ''}>
        <Formik
          initialValues={{ answers: ['', ''], question: '', trip_group: '' }}
          initialTouched={{
            question: true,
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={PollSchema}
          onSubmit={async (
            { question, answers },
            { setSubmitting, resetForm },
          ) => {
            await handleSubmitPoll({
              question,
              answers,
              setSubmitting,
              resetForm,
            })
          }}>
          {({ values, errors, setFieldValue }) => (
            <Form>
              <QuestionBar
                disabled={!canEdit}
                name="question"
                data-testid="question"
                maxLength="200"
                placeholder={CONSTANTS.POLL_ITEM_QUESTION_PLACEHOLDER}
                autoFocus={!tabletMobileViewGeneral}
              />
              {errors.question ? (
                <ErrorsMsg>{errors.question}</ErrorsMsg>
              ) : null}
              <FieldArray
                name="answers"
                render={arrayHelpers => (
                  <Body>
                    {values.answers && values.answers.length > 0
                      ? values.answers.map((answer, index) => {
                          return (
                            <div
                              key={index}
                              style={{ position: 'relative' }}
                              onMouseEnter={() => setHoverIndex(index)}
                              onMouseLeave={() => setHoverIndex(null)}>
                              <Answer
                                disabled={!canEdit}
                                name={`answers.${index}`}
                                data-testid="answer"
                                maxLength="200"
                                placeholder={`Answer ${index + 1}...`}
                              />
                              {index >= 2 ? (
                                <OptionCancelButton
                                  style={{
                                    display: hover !== index ? 'none' : '',
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}>
                                  <Icon />
                                </OptionCancelButton>
                              ) : null}
                            </div>
                          )
                        })
                      : null}

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      {values.answers.length < 5 ? (
                        <AddAnswerBtn
                          disabled={!canEdit}
                          onClick={() => arrayHelpers.push('')}
                          onTouchEnd={() => arrayHelpers.push('')}>
                          {CONSTANTS.POLL_ITEM_ADD_OPTIONS_BUTTON}
                        </AddAnswerBtn>
                      ) : null}
                    </Grid>
                    <SubmitWrapper>
                      <Field
                        name="trip_group"
                        formikKey="trip_group"
                        as={CustomSelectField}
                        setFieldValue={setFieldValue}
                      />
                      <SaveButton
                        disabled={
                          values.answers.length === 0 ||
                          values.answers.join('') === '' ||
                          values.question === '' ||
                          !selectedGroup
                        }>
                        {
                        loading
                        ? <Spinner/>
                        : CONSTANTS.POLL_ITEM_SUBMIT_BUTTON}
                      </SaveButton>
                    </SubmitWrapper>
                  </Body>
                )}
              />
            </Form>
          )}
        </Formik>
      </MTTooltip>

      <CreateGroupModal
        active={active}
        setActive={setActive}
        currentTrip={currentTrip.short_uid}
        user={user}
      />
    </Container>
  )
}

export default PollForm

/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useState, useRef, useEffect, Dispatch, SetStateAction } from 'react'

import styled from '@emotion/styled'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { usePause } from 'contexts/PauseContext'
import { CONSTANTS } from 'core/constants'
import { HTTPError } from 'ky'
import { cloneDeep, every, isEmpty, isNil, keys, negate, pickBy } from 'lodash'
import { Map } from 'pages/Workspace/layouts/Map'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import type { DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  removeAllPlacesFromState,
  initializeUserOwnedPlacesArray,
  resetMapInfoForSearchGridItem,
  setMapBounds,
  updateUserOwnedPlaceGroup,
} from 'store/slices/locationSearchResults'
import {
  loadTripGroups,
  setFilteredTripGroups,
  updateTripGroup,
} from 'store/slices/tripGroupSlice'
import { initializeTripItemColors } from 'store/slices/tripItemColor'
import { initializeLocatons } from 'store/slices/tripItemLocationsMap'
import { setTripUserRole } from 'store/slices/tripUserRoleSlice'
import { pageVariants } from 'styles/sharedMotion'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { setDocumentTitle } from 'utils/documentTitle'
import { tripItemTypes as _tripItemTypes } from 'utils/itemTypes'
import { sendNotification } from 'utils/toast'

import { findOneByShortUidOrUid, update, getTripGroups } from 'api/trip'
import { updateOrder as updateGroupsOrder } from 'api/tripGroups'
import { modifyPositions } from 'api/tripItemPositions'
import { updateOrder as updateLocationItemOrder } from 'api/tripLocationItem'
import { updateOrder } from 'api/tripNoteItem'
import { updateOrder as updatePollItemOrder } from 'api/tripPollItem'
import { updateTripCoords } from 'store/slices/tripCoords'

import {
  setupPlanningBoardGrid,
  parseTripItemColorsToState,
  getLocationItemsFromGroups,
} from './helper'
import { ItemPopup } from './layouts/ItemPopup'
import { NotesAndPolls } from './layouts/NotesAndPolls'
import { PlanningBoardGrid } from './layouts/PlanningBoardGrid'
import { Tags } from './layouts/Tags'
import { Container, Spinner, BoardContainer } from './planningBoard.style'
import {
  CurrentTrip,
  TripItemDefault,
  TripItemMetadataName,
  AllGridItemsPositionsHash,
  GridItemPosition,
  TripRole,
  UserTripRole,
  UserOwnedGooglePlaceIdsObject,
  UserOwnedGooglePlacesForMap,
  StateGrid,
  TripItemAPI,
  TripItemTypes,
  GridItemCrudArgs,
  TripItemCommonProps,
  PollOption,
  AddGridItemProps,
  UpdateGridItemProps,
  DeleteGridItemProps,
  LocationTripGroupItem,
} from './types'
import { tabletMobileScreenSize } from 'utils/screenSzie'

type SetupGridTagsObject = {
  [key: string]: boolean
}

type SelectedTagsKeysObject = {
  [key: string]: string
}

type OptionsObject = {
  [idx: number]: any
}

type TripItemTypesHash = {
  trips_polls_items: 'trips_polls_items'
  trips_notes_items: 'trips_notes_items'
  trips_locations_items: 'trips_locations_items'
}
const tripItemTypes: TripItemTypesHash = {
  trips_polls_items: 'trips_polls_items',
  trips_notes_items: 'trips_notes_items',
  trips_locations_items: 'trips_locations_items',
}

type Params = {
  trip_uid: string
}

type Props = {
  setCurrentTripCallback: (trip: any) => void
  screenHeight: number
  setSkipGuard: (val: boolean) => void
  isPlanningBoardTabSelected: boolean
  tripFormRef: any
  mapExpanded: boolean
  setMapExpanded: Dispatch<SetStateAction<boolean>>
}

const PlanningBoard = ({
  setCurrentTripCallback,
  screenHeight,
  setSkipGuard,
  isPlanningBoardTabSelected,
  tripFormRef,
  mapExpanded,
  setMapExpanded
}: Props) => {
  const dispatch = useAppDispatch()
  const params: Params = useParams()
  const history = useHistory()
  const tabletMobileView = tabletMobileScreenSize()

  const scrollableParentContainerRef = useRef(null)

  const { gridOrientation, setGridOrientation } = useGridOrientation()
  const popupEditGridItem = useAppSelector(state => state.popupEditGridItem)
  const tripLocationItems = useAppSelector(state => state.tripItemLocationsMap)
  const coordinates = useAppSelector(state => state.geoLocation)
  const { user } = useAppSelector(state => state.user)
  const { tripGroups, filteredTripGroups } = useAppSelector(
    state => state.tripGroupSlice,
  )
  const [loading, setLoading] = useState(false)
  const [tripItems, setTripItems] = useState<StateGrid>()
  const [mapSize, setMapSize] = useState('S')
  const [currentTrip, setCurrentTrip] = useState<CurrentTrip>({
    id: '',
    uid: '',
    short_uid: '',
    title: '',
    destination: '',
    lat: 0,
    lng: 0,
    grid_orientation: null,
    ne_bounds: [0, 0],
    sw_bounds: [0, 0],
    trip_metadata: {
      uid: '',
      bucket: '',
    },
    start_date: '',
    end_date: '',
    trips_members: [],
    trips_polls_items: [],
    trips_notes_items: [],
    trips_locations_items: [],
    trips_roles: [],
  })
  const [disableDragOnGridItems, setDisableDragOnGridItems] = useState(false)
  const { setPause } = usePause()
  const isInitialMount = useRef(true)
  const [groupUpdated, setGroupUpdated] = useState(false)
  const [selectedTags, setSelectedTags] = useState<
    SetupGridTagsObject | undefined
  >()
  const [isPollTabSelected, setIsPollTabSelected] = useState(true)

  useEffect(() => {
    // remove any searched places from explore page from state
    dispatch(removeAllPlacesFromState())
    dispatch(resetMapInfoForSearchGridItem())
  }, [dispatch])

  /**
   * Helper method used by @setupGrid in filtering trip items by color
   * @param {selectedTagsKeysObject} currently selected properties(tags/colors)
   * @param {item} tripItem
   * @returns true if a selected color exists in a tripItems's metadata
   */
  const containsCustomColors = (
    selectedTagsKeysObject: SelectedTagsKeysObject,
    item: TripItemDefault,
  ) => {
    const tripItemMetadataName: TripItemMetadataName = `trip_${item.type}_item_metadata`
    return selectedTagsKeysObject[item[tripItemMetadataName].color]
  }

  /**
   * Helper method used by @setupGrid in tags filtering
   * @param {selectedTagsKeysObject} currently selected tags
   * @param {item} tripItem
   * @returns true if a selected tag (filtering) exists in a tripItems's tags array (in parent level component)
   */
  const containsCustomTags = (
    selectedTagsKeysObject: SelectedTagsKeysObject,
    item: any,
  ) => {
    const arr = []
    for (const key of Object.keys(selectedTagsKeysObject)) {
      arr.push(selectedTagsKeysObject[key])
      // use val
    }
    const tagsOnlyFilteredArray = arr.filter(filter => {
      //remove the default tags or selected item type
      if (filter !== 'note' && filter !== 'poll' && filter !== 'location') {
        return filter
      }
    })

    for (const itemTag of item[`trips_${item.type}s_items_tags`]) {
      if (tagsOnlyFilteredArray.includes(itemTag.title)) {
        return true
      }
    }
    return false
  }

  /**
   * Setup planning board grid with the trip items.
   */
  const setupGrid = useCallback(
    async (tags?: SetupGridTagsObject) => {
      let allTripItems: any = []

      if (
        !isEmpty(currentTrip.trips_polls_items) ||
        !isEmpty(currentTrip.trips_notes_items) ||
        !isEmpty(currentTrip.trips_locations_items)
      ) {
        allTripItems = [
          ...currentTrip.trips_locations_items,
          ...currentTrip.trips_notes_items,
          ...currentTrip.trips_polls_items,
        ]
        setDisableDragOnGridItems(false)
      }
      let appliedColorFilter: string | undefined | false = undefined
      let appliedNoteItemFilter = 0
      let appliedPollItemFilter = 0
      let appliedLocationItemFilter = 0
      let appliedTagsFilter: string[] = []
      //Check if note, poll, location, tags filter is applied
      if (
        !isEmpty(tags) &&
        keys(pickBy(tags)) &&
        keys(pickBy(tags)).length > 0
      ) {
        //find returns the first matched element
        appliedColorFilter = keys(pickBy(tags)).find(a => a.includes('#'))
        appliedNoteItemFilter = keys(pickBy(tags)).findIndex(x => x === 'note')
        appliedPollItemFilter = keys(pickBy(tags)).findIndex(x => x === 'poll')
        appliedLocationItemFilter = keys(pickBy(tags)).findIndex(
          x => x === 'location',
        )
        appliedTagsFilter = keys(pickBy(tags)).filter(tag => {
          if (
            !tag.includes('#') &&
            tag !== 'note' &&
            tag !== 'poll' &&
            tag !== 'location'
          ) {
            return tag
          }
        })
      }

      const allItemsArray: any = []
      tripGroups.map(tripGroup => {
        if (tripGroup.tripItems) {
          allItemsArray.push(tripGroup.tripItems)
        }
      })
      allTripItems = [...allItemsArray.flat()]
      // if user filters by specific tag, remove any unecessary array item types from payload
      if (!isEmpty(tags)) {
        const allFalse = every(tags, negate(Boolean))
        const allTrue = every(tags)
        // if either all filters boxes are ticked OR
        // none of them are ticked
        // render all the items on the grid
        if (allFalse || allTrue) {
          await dispatch(setFilteredTripGroups(tripGroups))
          setDisableDragOnGridItems(false)
        } else {
          type TagsKeyObject = {
            [k: string]: string
          }
          const tagsKeysArray: Array<string> = keys(pickBy(tags))
          const selectedTagsKeysObject = tagsKeysArray.reduce(
            (o: TagsKeyObject, val: string) => {
              o[val] = val
              return o
            },
            {},
          )
          //Check if only color filter is applied
          let onlyColors = true
          tagsKeysArray.forEach(filter => {
            if (!filter.includes('#')) {
              onlyColors = false
            }
          })
          allTripItems = allTripItems.filter((item: any) => {
            if (
              appliedColorFilter &&
              (appliedNoteItemFilter > -1 ||
                appliedPollItemFilter > -1 ||
                appliedLocationItemFilter > -1) &&
              appliedTagsFilter.length > 0
            ) {
              if (
                containsCustomTags(selectedTagsKeysObject, item) &&
                selectedTagsKeysObject[item.type] &&
                containsCustomColors(selectedTagsKeysObject, item)
              ) {
                return item
              }
            } else if (
              appliedColorFilter &&
              (appliedNoteItemFilter > -1 ||
                appliedPollItemFilter > -1 ||
                appliedLocationItemFilter > -1)
            ) {
              if (
                selectedTagsKeysObject[item.type] &&
                containsCustomColors(selectedTagsKeysObject, item)
              ) {
                return item
              }
            } else if (appliedColorFilter && appliedTagsFilter.length > 0) {
              if (
                containsCustomTags(selectedTagsKeysObject, item) &&
                containsCustomColors(selectedTagsKeysObject, item)
              ) {
                return item
              }
            } else if (
              (appliedNoteItemFilter > -1 ||
                appliedPollItemFilter > -1 ||
                appliedLocationItemFilter > -1) &&
              appliedTagsFilter.length > 0
            ) {
              if (
                containsCustomTags(selectedTagsKeysObject, item) &&
                selectedTagsKeysObject[item.type]
              ) {
                return item
              }
            } else if (
              (appliedNoteItemFilter > -1 ||
                appliedPollItemFilter > -1 ||
                appliedLocationItemFilter > -1) &&
              !(appliedTagsFilter.length > 0) &&
              !appliedColorFilter
            ) {
              if (selectedTagsKeysObject[item.type]) {
                return item
              }
            } else if (onlyColors) {
              if (containsCustomColors(selectedTagsKeysObject, item)) {
                return item
              }
            } else if (appliedColorFilter) {
              if (
                (containsCustomTags(selectedTagsKeysObject, item) ||
                  selectedTagsKeysObject[item.type]) &&
                containsCustomColors(selectedTagsKeysObject, item)
              ) {
                return item
              }
            } else if (
              selectedTagsKeysObject[item.type] ||
              containsCustomTags(selectedTagsKeysObject, item) ||
              containsCustomColors(selectedTagsKeysObject, item)
            ) {
              return item
            }
          })

          const updatedFilteredTripGroups = tripGroups.map(tripGroup => {
            const filteredItemsOfCurrentGroup = tripGroup.tripItems.filter(
              tripGroupItem => allTripItems.includes(tripGroupItem),
            )
            const updatedTripGroup = { ...tripGroup }
            updatedTripGroup.tripItems = filteredItemsOfCurrentGroup
            return updatedTripGroup
          })
          await dispatch(setFilteredTripGroups(updatedFilteredTripGroups))

          setDisableDragOnGridItems(true)
        }
      }
      //now, find groups of the items

      const modifiedGridItems = setupPlanningBoardGrid({
        items: allTripItems.flat(),
        trip: currentTrip,
        count: tripGroups.length,
      })
      setTripItems({
        ...allTripItems.flat(),
        ...modifiedGridItems,
      })
    },
    [currentTrip, dispatch, tripGroups],
  )

  const onUpdateGridColumnOrientation = async (maxColLength: number) => {
    await update({
      user_uid: user.uid,
      short_uid: currentTrip.short_uid,
      uid: currentTrip.uid,
      grid_orientation: maxColLength,
    })
    setGridOrientation(maxColLength)
  }

  /**
   * Whenever items get moved around on the board, pass the
   * all the items on the board back to the API and update the
   * positions for any items that were moved.
   * @param { Object } modifiedMap React dnd grid object
   */
  const onUpdateColumnMap = async (
    modifiedMap: StateGrid,
    maxColLength: number,
    updateTripItemsStateObject = false,
  ) => {
    const { columns, tripsItems } = modifiedMap

    await onUpdateGridColumnOrientation(maxColLength)

    const newItemOrder = Object.values(columns).map((column, columnIndex) => {
      return column.tripItemIds.map((item, rowIndex) => {
        return {
          uid: tripsItems[item].uid,
          type: tripsItems[item].type,
          modified_position: {
            grid_row: rowIndex + 1,
            grid_col: columnIndex + 1,
          },
        }
      })
    })
    // update positions in the back end
    const all_grid_item_positions = await modifyPositions({
      short_uid: currentTrip.short_uid,
      user_uid: user.uid,
      positions: newItemOrder,
      column_length: maxColLength,
    })

    const allGridItemsPositionsHash: AllGridItemsPositionsHash = {}
    all_grid_item_positions.forEach((pos: GridItemPosition) => {
      if (pos.item_uid) {
        allGridItemsPositionsHash[pos.item_uid] = {
          grid_row: pos.grid_row,
          grid_col: pos.grid_col,
        }
      }
    })

    const copyOfCurrentTrip = currentTrip
    Object.values(_tripItemTypes).forEach(key => {
      if (!isEmpty(copyOfCurrentTrip[key as keyof CurrentTrip])) {
        const updatedKeyArray = copyOfCurrentTrip[key as keyof CurrentTrip].map(
          (item: TripItemAPI) => {
            if (allGridItemsPositionsHash[item.uid]) {
              const current = allGridItemsPositionsHash[item.uid]
              return {
                ...item,
                ...{
                  grid_position: {
                    grid_row: current.grid_row,
                    grid_col: current.grid_col,
                  },
                },
              }
            }
          },
        )
        copyOfCurrentTrip[key] = updatedKeyArray
        return copyOfCurrentTrip[key as keyof CurrentTrip]
      }
    })

    // update the currentTrip items in local state
    setCurrentTrip(copyOfCurrentTrip)

    const allTripItems = [
      ...(copyOfCurrentTrip[
        _tripItemTypes.TRIPS_POLLS_ITEMS as keyof CurrentTrip
      ] || []),
      ...(copyOfCurrentTrip[
        _tripItemTypes.TRIPS_NOTES_ITEMS as keyof CurrentTrip
      ] || []),
      ...(copyOfCurrentTrip[
        _tripItemTypes.TRIPS_LOCATIONS_ITEMS as keyof CurrentTrip
      ] || []),
    ]

    updateAllGridItems(allTripItems, updateTripItemsStateObject, maxColLength)
  }

  /**
   * When an add/remove operation occurs on the board on any item type,
   * the backend will send back an array of updated positions for all item
   * modalTypes. On the frontend, we need to update the positions of all current item
   * types and reassign them to all items so they can be updated on the grid for
   * the user.
   *
   * Note: this function only runs on add or remove for poll and note items only because
   * location items are added in the 'explore' tab which forces a rerender of the board
   * when the user navigates back to 'planning board' tab.
   *
   * @param { Array } all_grid_item_positions updated grid item positions
   * @param { Object } copyOfCurrentTrip Copy of current trip
   * @param { String } key item type on which an operation occured
   * @returns updated copy of trip
   */
  const onUpdateItemArraysWithNewPositions = (
    all_grid_item_positions: Array<GridItemPosition>,
    copyOfCurrentTrip: CurrentTrip,
    key: TripItemTypes,
  ) => {
    let allTripItems = []

    const allGridItemsPositionsHash: AllGridItemsPositionsHash = {}
    all_grid_item_positions.forEach(pos => {
      allGridItemsPositionsHash[pos.item_uid as keyof GridItemPosition] = {
        grid_row: pos.grid_row,
        grid_col: pos.grid_col,
      }
    })

    const itemTypes = cloneDeep(tripItemTypes)
    delete itemTypes[key]
    const [remainingKey1, remainingKey2] = Object.values(itemTypes)
    // for currently deleted item type, update positions of state trip object
    const updatedKey1Array = copyOfCurrentTrip[key].map(item => {
      const copyOfItem = { ...item }
      const updatedPosition = allGridItemsPositionsHash[item.uid]
      copyOfItem.grid_position = updatedPosition
      //item.grid_position = updatedPosition
      return copyOfItem
    })

    // for remaining item type, update positions of state trip object
    const updatedKey2Array = copyOfCurrentTrip[
      remainingKey1 as keyof CurrentTrip
    ].map((item: TripItemCommonProps) => {
      const copiedItem = { ...item }
      const updatedPosition = allGridItemsPositionsHash[copiedItem.uid]
      copiedItem.grid_position = updatedPosition
      return copiedItem
    })

    // for remaining item type, update positions of state trip object
    const updatedKey3Array = copyOfCurrentTrip[
      remainingKey2 as keyof CurrentTrip
    ].map((item: TripItemCommonProps) => {
      const updatedPosition = allGridItemsPositionsHash[item.uid]
      item.grid_position = updatedPosition
      return item
    })

    // update each item array with new item array that has positions update
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    copyOfCurrentTrip[key] = updatedKey1Array
    copyOfCurrentTrip[remainingKey1] = updatedKey2Array
    copyOfCurrentTrip[remainingKey2] = updatedKey3Array

    // update the currentTrip items in local state
    setCurrentTrip(oldy => ({ ...oldy, ...copyOfCurrentTrip }))

    allTripItems = [
      ...copyOfCurrentTrip[key],
      ...(copyOfCurrentTrip[remainingKey1 as keyof CurrentTrip] || []),
      ...(copyOfCurrentTrip[remainingKey2 as keyof CurrentTrip] || []),
    ]

    updateAllGridItems(allTripItems)

    return copyOfCurrentTrip
  }

  /**
   * Update grid items whenever a new item is added, updated or deleted.
   * This function is NOT part of useDeepCompareEffect because if a single
   * grid item is added, updated or deleted, useDeepCompareEffect forces
   * the entire item grid to rerender, causing a glitchy user experience.
   * Running this function guarantees that only one item on the entire grid
   * is altered, leaving the rest of them in place.
   * @param { Array } allTripItems all trip items of the current trip
   */
  const updateAllGridItems = (
    allTripItems: Array<TripItemAPI>,
    updateTripItemsStateObject = true,
    colCount?: number,
  ) => {
    const modifiedGridItems = setupPlanningBoardGrid({
      items: allTripItems,
      trip: currentTrip,
      count: colCount ?? gridOrientation,
    })

    if (updateTripItemsStateObject) {
      setTripItems(modifiedGridItems)
    }
  }

  /**
   * Add poll option
   * @param { Object } data data to delete
   * @param { Object } currentTrip current trip in state
   * @param { String } key item type key
   * @returns update trip object
   */

  /**
   * Update poll option
   * @param { Object } data data to delete
   * @param { Object } currentTrip current trip in state
   * @param { String } key item type key
   * @returns update trip object
   */
  const onPollOptionUpdate = (
    data: GridItemCrudArgs,
    currentTripData: CurrentTrip,
    key: 'trips_polls_items',
  ) => {
    const obj = currentTripData[key]
    let pollToModIndex = null

    const optionsObj: OptionsObject = {}

    obj.forEach((poll, index) => {
      const optionsArray = poll.trip_poll_item_options.map(option => {
        const tempOption = { ...option }
        if (tempOption.uid === data.uid) {
          pollToModIndex = index
          // edge case: option has no votes yet so just add the users
          if (tempOption.users.length === 0) {
            tempOption.users = [...tempOption.users, ...data.users]
          } else {
            const tempUsersArray = data?.users?.map(user => {
              return {
                uid: user.uid,
                firstName: user.firstName,
                lastName: user.lastName,
              }
            })
            tempOption.users = tempUsersArray
          }
          return tempOption
        }
        return tempOption
      })
      optionsObj[index] = optionsArray
      return optionsArray
    })

    if (pollToModIndex) {
      currentTripData[key][pollToModIndex]['trip_poll_item_options'] = [
        ...[],
        ...optionsObj[pollToModIndex],
      ]
    }

    return currentTripData
  }

  /**
   * Delete poll option
   * @param { Object } data data to delete
   * @param { Object } currentTrip current trip in state
   * @param { String } key item type key
   * @returns update trip object
   */
  const onPollOptionDelete = (
    data: GridItemCrudArgs,
    currentTrip: CurrentTrip,
    key: 'trips_polls_items',
  ) => {
    const obj = currentTrip[key]

    const pollContainingDeletableOption = obj.map(poll => {
      const foundOption = poll.trip_poll_item_options.some(
        option => option.uid === data.uid,
      )
      if (foundOption) return poll
    })

    let updatedPollOptions: PollOption[] | undefined = []
    if (pollContainingDeletableOption.length > 0) {
      const pollToUpdate = pollContainingDeletableOption[0]

      updatedPollOptions = pollToUpdate?.trip_poll_item_options.filter(
        option => option.uid !== data.uid,
      )

      const pollToModIndex = currentTrip[key].findIndex(poll => {
        if (pollToUpdate?.uid) {
          return (poll.uid = pollToUpdate?.uid)
        }
      })

      if (updatedPollOptions) {
        currentTrip[key][pollToModIndex]['trip_poll_item_options'] = [
          ...[],
          ...updatedPollOptions,
        ]
      }
    }

    return currentTrip
  }

  /**
   * Hide the map and reset map interaction states
   */
  const onToggleMap = async (height: number) => {
    setPause(true)
    let currentMapSize = ''
    const isSmallMap = height < 600
    if (isSmallMap) {
      currentMapSize = 'SMALL'
      setMapSize('S')
    } else {
      currentMapSize = 'SMALL'
      setMapSize('S') //Hack
    }
  }

  /**
   * Callback that filters tags on the planning board grid.
   * @param {Object} tags trip tags
   */
  const onHandleTagsChecked = async (tags: any) => {
    setupGrid(tags)
    setSelectedTags(tags)
  }

  /**
   * Based on type of item clicked (location, note, poll)
   * open the popup modal and pass down the specific trip item
   * with all of its props.
   *
   * @returns ItemPopup Component
   */
  const onPrepareAndOpenGridItemPopupModal = () => {
    const tripMembers = currentTrip && currentTrip.trips_members
    let filteredItem: any = []

    for (const tripGroup of tripGroups) {
      for (const item of tripGroup.tripItems) {
        if (item.uid === popupEditGridItem?.data?.uid) {
          filteredItem = item
          break
        }
      }
    }

    return (
      <>
        {filteredItem && (
          <ItemPopup
            open={!!popupEditGridItem?.data?.open}
            tripShortUid={currentTrip.short_uid}
            tripItem={filteredItem}
            tripMembers={tripMembers}
            bucket={currentTrip?.trip_metadata?.bucket}
          />
        )}
      </>
    )
  }

  /**
   * Do a deep comparisson of current trip's nested props
   * to check for every single change on every re-render.
   */
  useDeepCompareEffect(() => {
    const getTrip = async () => {
      setLoading(true)

      try {
        if (history && history?.location?.search.includes('member_confirmed')) {
          sendNotification(CONSTANTS.TRIP_MEMBER_CONFIRMED_BY_ADMIN, 'success')
        } else if (
          history &&
          history?.location?.search.includes('member_denied')
        ) {
          sendNotification(CONSTANTS.TRIP_MEMBER_DENIED_BY_ADMIN, 'success')
        }

        const items = tripGroups.map(tripGroup => {
          return tripGroup.tripItems
        })
        dispatch(
          initializeTripItemColors(parseTripItemColorsToState(items.flat())),
        )

        // run this code ONLY on initial page render
        if (isInitialMount.current) {
          if (currentTrip.uid === '') {
            const trip: CurrentTrip = await findOneByShortUidOrUid({
              short_uid: params.trip_uid,
              user_uid: user.uid,
            })

            setDocumentTitle(trip.title)

            const tripsRoles: Array<TripRole> = []

            if (trip?.trips_roles) {
              trip.trips_roles.forEach((user: UserTripRole) =>
                tripsRoles.push({
                  user_uid: user.uid,
                  role: user.role,
                  role_uid: user.role_uid,
                }),
              )
            }

            setCurrentTripCallback(trip)
            const tripGroupRes = await getTripGroups(trip.uid)
            await dispatch(loadTripGroups(tripGroupRes))
            await dispatch(setFilteredTripGroups(tripGroupRes))
            setCurrentTrip(oldTrip => ({ ...oldTrip, ...trip }))
            setGridOrientation(trip.grid_orientation)
            // initalize trip role for the user to grant permissions
            // on viewing and accessing parts of the app
            dispatch(setTripUserRole(tripsRoles))
            dispatch(
              updateTripCoords({
                lat: trip.lat,
                lng: trip.lng,
              }),
            )

            dispatch(
              setMapBounds({
                ne_bounds: trip.ne_bounds,
                sw_bounds: trip.sw_bounds,
              }),
            )

            const currentTripLocationItems =
              getLocationItemsFromGroups(tripGroups)

            if (
              currentTripLocationItems &&
              currentTripLocationItems.length > 0
            ) {
              const userOwnedGooglePlaceIdsArray: Array<UserOwnedGooglePlaceIdsObject> =
                []
              const userOwnedGooglePlacesForMap: Array<UserOwnedGooglePlacesForMap> =
                []

              if (tripGroups.length > 0) {
                let trip_group_uid: string
                tripGroups.forEach(tripGroup => {
                  trip_group_uid = tripGroup.uid

                  currentTripLocationItems.forEach(item => {
                    userOwnedGooglePlaceIdsArray.push({
                      place_id: (item as LocationTripGroupItem).google_place_id,
                      color: (item as LocationTripGroupItem)
                        .trip_location_item_metadata.color,
                      trip_group_uid,
                    })
                    userOwnedGooglePlacesForMap.push({
                      place_id: (item as LocationTripGroupItem).google_place_id,
                      uid: (item as LocationTripGroupItem).uid,
                      lat: +(item as LocationTripGroupItem).lat,
                      lng: +(item as LocationTripGroupItem).lng,
                      color: (item as LocationTripGroupItem)
                        .trip_location_item_metadata.color,
                      title: (item as LocationTripGroupItem).title,
                    })
                  })
                })
              }

              // display all location items as markers on pb
              dispatch(initializeLocatons(userOwnedGooglePlacesForMap))
              // determine if user already has this location when adding a new one
              // from the mapInfo component in Search Tab
              dispatch(
                initializeUserOwnedPlacesArray(userOwnedGooglePlaceIdsArray),
              )

              setupGrid()
            } else if (
              isEmpty(trip?.trips_polls_items) ||
              isEmpty(trip?.trips_notes_items) ||
              isEmpty(trip?.trips_locations_items)
            ) {
              setupGrid()
            }
            isInitialMount.current = false
          }
        }
        setLoading(false)
      } catch (error) {
        const res = await (error as HTTPError)?.response?.json()

        if (res?.message === 'You are not part of this trip!') {
          setSkipGuard(true)
          return history.push('/create-trip')
        }
        if (
          !isNil(res?.message) &&
          res?.message !== CONSTANTS.EXPIRED_REFRESH_TOKEN_ERROR_API
        ) {
          await history.push('/404', {
            message: 'Error occured',
          })
        }
        console.log('Error:', error)
        setLoading(false)
      }
    }

    getTrip()
  }, [
    params,
    currentTrip,
    tripLocationItems.userOwnedLocations.length,
    groupUpdated, //doing recently
  ])

  useEffect(() => {
    setupGrid(selectedTags)
  }, [selectedTags, setupGrid])
  /**
   * Modify grid item style when it is being dragged or hovered over.
   *
   * @param { boolean } isDragging item is dragged to new position
   * @param { CSSProperties } draggableStyle style of dragged item
   * @returns
   */
  type DragEndResult = {
    destination?: DraggableLocation | undefined
    source: SourceDragEnd
    draggableId: string
    type: string
  }

  type SourceDragEnd = {
    droppableId: string
    index: number
  }
  const boardWidthRef = useRef<HTMLDivElement>(null)
  const onDragEnd = async (result: DropResult) => {
    const { source, destination, type } = result
    if (!destination) return
    if (type === 'boards') {
      if (source.droppableId === destination?.droppableId) {
        // Move Boards
        const tripGroupIds = tripGroups.map(tripGroup => {
          return tripGroup.id
        })
        const element = tripGroupIds.splice(source.index, 1)[0] //will give us the from/element/index to be shifted
        tripGroupIds.splice(destination?.index, 0, element) // place that element in the to/desired poition.

        const copyOfCurrentTrip = [...tripGroups]
        const sorted = copyOfCurrentTrip.sort((a, b) => {
          const indexA = tripGroupIds.findIndex(id => a.id === id)
          const indexB = tripGroupIds.findIndex(id => b.id === id)
          return indexA - indexB // to get positive, 0, negative number for the sort callback.
        })

        const data = {
          trip_groups: sorted,
        }

        try {
          const res = await updateGroupsOrder(data)
          if (res) {
            sendNotification(
              `${CONSTANTS.TRIP_GROUP_ITEM_UPDATE_SUCCESS}`,
              'success',
            )
          }
        } catch (error) {
          const e = await (error as HTTPError)?.response?.json()
          const message = e?.message || error
          sendNotification(message, 'error')
          console.log('error occured: ', error)
        }

        // moving data between columns
        const start: any = tripItems?.columns[`column${source?.index + 1}`]
        const finish: any =
          tripItems?.columns[`column${destination?.index + 1}`]
        if (start?.tripItemIds) {
          const startTripItemIds = Array.from(start?.tripItemIds)
          startTripItemIds.splice(source.index, startTripItemIds.length)

          const finishTripItemIds = Array.from(finish?.tripItemIds)
          finishTripItemIds.splice(destination.index, 0, destination?.index + 1)
        }
        //ADD API FOR STORING THE ORDER IN DATABASE

        dispatch(loadTripGroups(sorted))
        dispatch(setFilteredTripGroups(sorted))
      }
    } else {
      const { destination, source, draggableId } = result

      // item dropped outside of grid
      if (!destination) return

      // if user dropped the item in the same place where it was picked up from
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }

      const sourceGroup = tripGroups[+source?.droppableId]
      let destinationGroup: any = {}
      if (destination && destination.droppableId) {
        destinationGroup = tripGroups[+destination?.droppableId]
      }

      let poppeditem: any = {}

      const sourceGroupItemsWithoutPoppedItem = sourceGroup?.tripItems?.filter(
        item => {
          if (item.id !== draggableId) {
            return item
          }
          poppeditem = item
        },
      )

      // moving data in the same column
      if (sourceGroup.id === destinationGroup.id) {
        const allTripItems = [...sourceGroup.tripItems]
        allTripItems.splice(source.index, 1)
        allTripItems.splice(destination.index, 0, poppeditem)
        const newUpdatedSourceGroup = {
          uid: sourceGroup.uid,
          short_uid: sourceGroup.short_uid,
          title: sourceGroup.title,
          color: sourceGroup.color,
          order: sourceGroup.order,
          id: sourceGroup.id,
          created_by: sourceGroup.created_by,
          tripItems: allTripItems,
        }
        await dispatch(updateTripGroup(newUpdatedSourceGroup))

        const data = {
          uid: undefined,
          allItems: newUpdatedSourceGroup.tripItems,
          trip_group: newUpdatedSourceGroup.id,
          coordinates,
        }

        try {
          let res = {}
          if (poppeditem.type === 'note') {
            res = await updateOrder(data)
            if (res) {
              sendNotification(
                `${CONSTANTS.TRIP_NOTE_ITEM_UPDATE_SUCCESS}`,
                'success',
              )
            }
          } else if (poppeditem.type === 'poll') {
            res = await updatePollItemOrder(data)
            if (res) {
              sendNotification(
                `${CONSTANTS.TRIP_POLL_ITEM_UPDATE_SUCCESS}`,
                'success',
              )
            }
          } else if (poppeditem.type === 'location') {
            res = await updateLocationItemOrder(data)
            if (res) {
              sendNotification(
                `${CONSTANTS.TRIP_LOCATION_ITEM_UPDATE_SUCCESS}`,
                'success',
              )
            }
          }
        } catch (error) {
          const e = await (error as HTTPError)?.response?.json()
          const message = e?.message || error
          sendNotification(message, 'error')
          console.log('error occured: ', error)
        }

        return
      }

      // moving data between columns

      const newUpdatedSourceGroup = {
        uid: sourceGroup.uid,
        title: sourceGroup.title,
        color: sourceGroup.color,
        short_uid: sourceGroup.short_uid,
        order: sourceGroup.order,
        id: sourceGroup.id,
        created_by: sourceGroup.created_by,
        tripItems: sourceGroupItemsWithoutPoppedItem,
      }

      const updatedDestinationItems = [...destinationGroup.tripItems] //all destinationGroupItems

      updatedDestinationItems.splice(destination.index, 0, poppeditem) ////all destinationGroupItems + poppedItem
      const newUpdatedDestinationGroup = {
        uid: destinationGroup.uid,
        title: destinationGroup.title,
        color: destinationGroup.color,
        short_uid: destinationGroup.short_uid,
        order: destinationGroup.order,
        id: destinationGroup.id,
        created_by: destinationGroup.created_by,
        tripItems: updatedDestinationItems,
      }

      await dispatch(updateTripGroup(newUpdatedSourceGroup))
      await dispatch(updateTripGroup(newUpdatedDestinationGroup))

      const data = {
        uid: poppeditem.uid,
        allItems: newUpdatedDestinationGroup.tripItems,
        trip_group: newUpdatedDestinationGroup.id,
        coordinates,
      }

      if (poppeditem.type === 'location') {
        dispatch(
          updateUserOwnedPlaceGroup({
            place_id: poppeditem?.google_place_id,
            trip_group_uid: destinationGroup?.uid,
          }),
        )
      }
      try {
        let res = {}
        if (poppeditem.type === 'note') {
          res = await updateOrder(data)
          if (res) {
            sendNotification(
              `${CONSTANTS.TRIP_NOTE_ITEM_UPDATE_SUCCESS}`,
              'success',
            )
          }
        } else if (poppeditem.type === 'poll') {
          res = await updatePollItemOrder(data)
          if (res) {
            sendNotification(
              `${CONSTANTS.TRIP_POLL_ITEM_UPDATE_SUCCESS}`,
              'success',
            )
          }
        } else if (poppeditem.type === 'location') {
          res = await updateLocationItemOrder(data)
          if (res) {
            sendNotification(
              `${CONSTANTS.TRIP_LOCATION_ITEM_UPDATE_SUCCESS}`,
              'success',
            )
          }
        }
      } catch (error) {
        const e = await (error as HTTPError)?.response?.json()
        const message = e?.message || error
        sendNotification(message, 'error')
        console.log('error occured: ', error)
      }
    }
  }

  const Wrapper = styled.div`
    grid-row: 3;
  `
  const Boards = styled.div``

  const elementRef = useRef(null)

  useEffect(() => {
    const currentTripLocationItems = getLocationItemsFromGroups(tripGroups)

    if (currentTripLocationItems && currentTripLocationItems.length > 0) {
      const userOwnedGooglePlaceIdsArray: Array<UserOwnedGooglePlaceIdsObject> =
        []
      const userOwnedGooglePlacesForMap: Array<UserOwnedGooglePlacesForMap> = []

      if (tripGroups.length > 0) {
        let trip_group_uid: string
        tripGroups.forEach(tripGroup => {
          trip_group_uid = tripGroup.uid

          currentTripLocationItems.forEach(item => {
            userOwnedGooglePlaceIdsArray.push({
              place_id: (item as LocationTripGroupItem).google_place_id,
              color: (item as LocationTripGroupItem).trip_location_item_metadata
                .color,
              trip_group_uid,
            })
            userOwnedGooglePlacesForMap.push({
              place_id: (item as LocationTripGroupItem).google_place_id,
              uid: (item as LocationTripGroupItem).uid,
              lat: +(item as LocationTripGroupItem).lat,
              lng: +(item as LocationTripGroupItem).lng,
              color: (item as LocationTripGroupItem).trip_location_item_metadata
                .color,
              title: (item as LocationTripGroupItem).title,
            })
          })
        })
      }
      dispatch(initializeLocatons(userOwnedGooglePlacesForMap))
    }
  }, [dispatch, tripGroups])

  //A workaround to the react-dnd library not working perfection when scrolled horizontally.
  useEffect(() => {
    const parentElement =
      scrollableParentContainerRef.current! as HTMLDivElement
    if (parentElement) {
      const firstChild: HTMLDivElement =
        parentElement.firstChild as HTMLDivElement
      if (firstChild) {
        firstChild.style!.minWidth = 'fit-content'
      }
    }
  })

  return (
      <Container
        tabletMobileView={tabletMobileView}
        style={{
          marginTop: loading ? '20rem' : '2rem',
        }}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}>
        {loading ? (
          <Spinner>
            <CircularProgress />
          </Spinner>
        ) : (
          <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', maxWidth: tabletMobileView ? '100vw' : '70vw' }}>
            <div>
              <div style={{ display: 'flex', maxWidth: tabletMobileView ? '100vw' : '70vw', gap: '10px', flexWrap:'wrap'}}>
                <Tags
                  onHandleTagsChecked={onHandleTagsChecked}
                />

                <NotesAndPolls
                  setIsPollTabSelected={setIsPollTabSelected}
                  currentTrip={currentTrip}
                />
              </div>
              { tabletMobileView &&
              <div style={{ width: '100%'}}>
              <Map
                tripShortUid={currentTrip.short_uid}
                destinationLat={currentTrip.lat}
                destinationLng={currentTrip.lng}
                showMapInfo={false}
                mapExpanded={mapExpanded}
                setMapExpanded={setMapExpanded}
                showExpandButton={true}
              />
              </div>
              }

              <Grid item>
                <BoardContainer>
                  <div
                    style={{ width: mapExpanded ? '70vw' : 'calc(100vw - 20px)' }}
                    ref={scrollableParentContainerRef}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable
                        droppableId={currentTrip.short_uid}
                        type={'boards'}
                        direction={'horizontal'}>
                        {({ droppableProps, innerRef }) => (
                          <Wrapper ref={innerRef} {...droppableProps}>
                            <Boards ref={boardWidthRef}>
                              {tripItems && (
                                <PlanningBoardGrid
                                  bucket={currentTrip?.trip_metadata?.bucket}
                                  tripMembersCount={
                                    currentTrip?.trips_members?.length
                                  }
                                  groupUpdated={groupUpdated}
                                  filteredGroups={filteredTripGroups}
                                  setGroupUpdated={setGroupUpdated}
                                  mapSize={mapSize}
                                  tripItems={tripItems}
                                  tripShortUid={currentTrip.short_uid}
                                  onUpdateColumnMap={onUpdateColumnMap}
                                  disableDragOnGridItems={
                                    disableDragOnGridItems
                                  }
                                  setTripItems={setTripItems}
                                />
                              )}
                            </Boards>
                          </Wrapper>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </BoardContainer>
              </Grid>
            </div>
            {!isEmpty(popupEditGridItem) &&
              !isEmpty(popupEditGridItem?.data) &&
              onPrepareAndOpenGridItemPopupModal()}
          </div>
                {!tabletMobileView &&
                  <div style={{ width: 'calc(29vw - 16px)', marginLeft: '16px', height: mapExpanded ? '' : 'fit-content'}}>
                    <Map
                    mapExpanded={mapExpanded}
                    setMapExpanded={setMapExpanded}
                    tripShortUid={currentTrip.short_uid}
                    destinationLat={currentTrip.lat}
                    destinationLng={currentTrip.lng}
                    showMapInfo={false}
                    showExpandButton={true}
                  />
                  </div>
              }
              </div>
        )}
      </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    tripGroups: state.tripGroups,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  updateTripGroup: (payload: any) => dispatch(updateTripGroup(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanningBoard)

/* eslint-disable quotes */
export const CONSTANTS = {
  EXPIRED_REFRESH_TOKEN_ERROR_API: 'Please login again!',
  EXPIRED_REFRESH_TOKEN_ERROR:
    'You have been logged out for too long! Please sign in again!',
  ERROR_JWT_EXPIRED:
    'Looks like that email link expired! Please request the trip admin for a new one!',
  START_TRIP: 'Plan new trip',
  ADD_TO_BOARD: 'Add to Board',
  ALREADY_PART_OF_BOARD: 'Added',
  HOURS: 'Hours',

  APP_MOBILE_WARNING:
    ' We are building a great mobile experience for you! In the meantime, please enjoy MiTravel on your desktop computer!',

  ERROR_GENERAL: 'Oops! Looks like an error occured!',
  FOUR_OH_FOUR: 'Return Home',
  SETTINGS: 'Settings',

  SIGN_IN: 'Sign In',
  SIGN_OUT: 'Sign Out',
  LOG_IN: 'Log in',
  PROFILE: 'Profile',
  RESET_PASSWORD: 'Reset Your Password',
  DONT_HAVE_ACCOUNT_SIGN_UP: "Don't have an account? Sign up",
  SIGN_IN_ERROR_MESSAGE: 'Uh-oh! There was a problem logging in!',
  SIGN_IN_WELCOME_MESSAGE: 'Welcome',
  SIGN_IN_HEADER_SUBTITLE: 'Plan your next exciting trip!',

  SIGN_UP: 'Sign Up',
  SIGN_UP_HEADER_TITLE: 'Plan better, plan together',
  SIGN_UP_HEADER_SUBTITLE: 'Sign up and plan your first trip today!',

  SIGN_OUT_SUCCESS: 'You have signed out successfully',

  ALREADY_HAVE_ACCOUNT_SIGN_IN: 'Already have an account? Sign in',

  LABEL_FIRST_NAME: 'First name',
  LABEL_LAST_NAME: 'Last name',
  LABEL_PASSWORD: 'Password',
  LABEL_EMAIL: 'Email',
  LABEL_USER_NAME: 'User name',
  LABEL_REMEMBER_ME: 'Remember me',
  LABEL_BUTTON_FORGOT_ME: 'Forgot Password?',
  PASSWORD_EMAIL_SENT_SUCCESS_MESSAGE:
    'Password reset email was successfully sent.',
  PASSWORD_RESET_SUCCESS_MESSAGE: 'Password successfully reset.',
  PASSWORD_RESET_DONT_MATCH_MESSAGE: 'Passwords need to match!',

  TABS_PLANNING_BOARD: 'Planning Board',
  TABS_EXPLORE: 'Explore',
  TABS_ITINERARY: 'Itinerary (Coming Soon)',

  TRIP_ITEM_LOCATION: 'Successfully added item to board!',

  DASHBOARD_TEXT:
    'Click “Plan New Trip” to start planning your trip today. You can also invite and collaborate with other people on the board.',
  DASHBOARD_TUTORIAL_TEST_PREFIX: 'First time using MiTravel? Check out the ',
  DASHBOARD_TUTORIAL_TEST_LINK: 'step-by-step tutorial walkthrough videos',
  DASHBOARD_TUTORIAL_TEST_SUFFIX: ' we recorded for you.',
  DASHBOARD_SUPPORT_TEXT_PREFIX: 'If you come across any issues, please email ',
  DASHBOARD_SUPPORT_TEXT_SUFFIX: ' for assistance. Happy trip planning!',
  DASHBOARD_SUPPORT_EMAIL: 'support@mitravelapp.com',
  DASHBOARD_TITLE: 'Trips',
  DASHBOARD_VIEW_ALL_TRIPS: 'View All Trips',
  DASHBOARD_LAST_MODIFIED: 'Last Modified',

  DASHBOARD_NO_CURRENT_TRIPS_PLANNED: 'No Current Trips Planned',
  DASHBOARD_READY_T0_PLAN_NEXT_ADV: 'Ready to plan your next adventure?',

  CREATE_NEW_TRIP_LABEL_DESTINATION: 'Destination',
  CREATE_NEW_TRIP_LABEL_DATES: 'Date (Optional)',

  CREATE_NEW_TRIP_INPUT_PLACEHOLDER: 'Enter Country, Region, or City',
  TRIP_CREATION_SUCCESS: 'Woot! Successfully created trip!',

  TRIP_MEMBER_INVITATION_SUCCESS: 'Successfully invited member',
  TRIP_MEMBER_ROLE_CHANGE_SUCCESS: "Successfully updated member's role",

  TAGS_DUPLICATE_ERROR: 'Duplicate label detected, Try a different one!',
  ADD_CUSTOM_TAGS_PLACEHOLDER: 'Add Tag',

  POLL_ITEM_QUESTION_MAX_LENGTH_MESSAGE: 'Too Long!',
  POLL_ITEM_QUESTION_MIN_LENGTH_MESSAGE: 'Too Short!',
  POLL_ITEM_QUESTION_REQUIRED_MESSAGE: 'Required',
  POLL_ITEM_QUESTION_PLACEHOLDER: 'Ask a question...',
  POLL_ITEM_SUBMIT_BUTTON: 'Post',
  POLL_ITEM_ADD_OPTIONS_BUTTON: 'Add option',
  POLL_ITEM_ADD_OPTIONS_SUCCESS: 'Successfully added new option!',
  POLL_ITEM_ADD_EMPTY_OPTION_ERROR: "You can't add an empty option!",
  POLL_ITEM_ADD_DUPLICATE_OPTION_ERROR: 'Duplicate options!',
  POLL_ITEM_DUPLICATE_OPTION_FOUND_ERROR: 'Option already exists!',
  CREATE_NEW_POLL_ITEM: 'Poll successfully created!',
  CREATE_NEW_POLL_ITEM_ERROR:
    'Oops! Something went wrong while creating the poll.',
  POLL_ITEM_VOTE_SUCCESS: 'Voted on',
  EMPTY_POLL_TITLE_ERROR: "Title can't be blank!",
  UPDATE_POLL_TITLE_PERMISSION_ERROR:
    "You don't have permission to edit this title.",
  DELETE_POLL_PERMISSION_ERROR:
    "You don't have permission to delete this poll.",
  DELETE_POLL_OPTION_PERMISSION_ERROR:
    "You don't have permission to delete this option.",
  ADD_POLL_OPTIONS_PERMISSION_ERROR:
    "You don't have permission to add options to this poll.",
  DELETE_CONFIRMATION: 'Are you sure you want to delete this',
  DELETE_CONFIRMATION_SUFFIX:
    ' Once it is deleted, you will not be able to retrieve it.',
  DELETE_ITEM_DENY: "You don't have permission to delete this item",
  CARD_TO_BE_DELETED: 'card?',
  COMMENT_TO_BE_DELETED: 'comment?',
  TRIP_TO_BE_DELETED: 'trip?',
  TRIP_GROUP_TO_BE_DELETED: 'trip group?',
  OPTION_TO_BE_DELETED: 'option?',

  CHANGE_COLOR_PERMISSION_ERROR:
    "You don't have permission to change the color.",

  DELETE_POLL_OPTION_SUCESS: 'Successfully deleted ',

  DUPLICATE_NEW_TRIP_ITEM: 'Card was duplicated successfully!',
  DUPLICATE_NEW_TRIP_ITEM_ERROR:
    'Failed to duplicate card. Please try again later.',

  CREATE_NEW_TRIP_NOTES_ITEM: 'Note successfully created!',
  CREATE_NEW_TRIP_NOTES_ITEM_ERROR:
    'Oops! Something went wrong while creating the note.',
  CREATE_NEW_TRIP_NOTES_ITEM_PLACEHOLDER: 'Write your note...',
  CREATE_NEW_TRIP_NOTES_ITEM_SUBMIT_BUTTON: 'Post',
  CREATE_NEW_TRIP_NOTES_ITEM_TITLE_PLACEHOLDER: 'Write a note...',
  CREATE_NEW_TRIP_NOTES_ITEM_TITLE_MIN_LENGTH_MESSAGE: 'Too short!',
  CREATE_NEW_TRIP_NOTES_ITEM_TITLE_REQUIRED_MESSAGE: 'Required',
  CREATE_NEW_TRIP_NOTES_ITEM_TITLE_MAX_LENGTH_MESSAGE: 'Too long!',

  CREATE_TRIP_PAGE_CREATE_NEW_TRIP: 'Create a new trip',
  CREATE_TRIP_PAGE_INVITE_TRAVELMATES: 'invite travelmates',
  CREATE_TRIP_PAGE_START_PLANNING: 'Start Planning',

  COLLABORATORS_MODAL_INVITE_TRAVEL_TO_BE: 'Invite traveler to be',
  COLLABORATORS_MODAL_SEND_BUTTON: 'Send',
  COLLABORATORS_MODAL_INVITATION_TO_SELF_ERROR: "You can't invite yourself!",
  COLLABORATORS_MODAL_MEMBER_ALREADY_PART_OF_TRIP_ERROR:
    'This member is already part of your trip',
  COLLABORATORS_MODAL_MEMBER_ALREADY_INVITED_ERROR:
    'This user is already invited',
  COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_SUCCESS:
    'The member has been successfully removed!',
  COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_ERROR:
    'Oops! Something went wrong while removing the member.',
  COLLABORATORS_MODAL_MEMBER_REMOVE_ADMIN_ERROR: "You can't remove the admin!",

  NOTE_POLL_CONTAINER_CREATE_POLL: 'Create a poll',
  NOTE_POLL_CONTAINER_CREATE_NOTE: 'Write a note',

  UPDATE_TRIP_ITEM_TAGS_PERMISSION_ERROR:
    "You don't have permission to edit tags.",

  TRIP_NOTE_ITEM_UPDATE_SUCCESS: 'Successfully updated note item!',
  TRIP_GROUP_ITEM_UPDATE_SUCCESS: 'Successfully updated trip group!',
  DELETE_NOTE_PERMISSION_ERROR:
    "You don't have permission to delete this note.",
  TRIP_NOTE_ITEM_DELETE_SUCCESS: 'Successfully deleted trip note item!',
  TRIP_GROUP_CREATE_SUCCESS: 'Successfully created trip group!',
  TRIP_GROUP_UPDATE_SUCCESS: 'Successfully updated trip group title!',
  TRIP_LOCATION_ITEM_UPDATE_SUCCESS: 'Successfully updated location item!',
  TRIP_LOCATION_ITEM_COLOR_UPDATE_SUCCESS: 'Successfully updated color!',
  TRIP_LOCATION_ITEM_DELETE_SUCCESS: 'Successfully deleted trip location item!',

  TRIP_POLL_ITEM_UPDATE_SUCCESS: 'Successfully updated poll item!',
  TRIP_POLL_ITEM_COLOR_UPDATE_SUCCESS: 'Successfully updated color!',

  TRIP_POLL_ITEM_DELETE_SUCCESS: 'Successfully deleted trip poll item!',
  TRIP_DELETE_SUCCESS: 'Successfully deleted trip!',
  TRIP_GROUP_DELETE_SUCCESS: 'Successfully deleted trip group!',

  TRIP_MEMBER_CONFIRMED_BY_ADMIN:
    'The member has successfully been added to the trip!',
  TRIP_MEMBER_DENIED_BY_ADMIN:
    'You have successfully denied access to the trip member!',

  SINGLE_VOTE_UNIT: 'person',
  MULTIPLE_VOTES_UNIT: 'people',

  EXPLORE_PAGE_TAB_DESTINATION: 'Search Destination & Activity',
  EXPLORE_PAGE_TAB_INSPIRATION: 'Search Inspiration (Coming Soon)',

  HERO_TITLE_SUCCESSFULLY_UPDATED: 'Title successfully updated',
  HERO_DATES_SUCCESSFULLY_UPDATED: 'Dates successfully updated',

  TRIP_LOCATION_ITEM_SELECT_ALERT: 'Please select the trip group!',

  DOWNGRADE_REASONS: [
    "I won't be traveling anytime soon",
    "It's too expensive",
    "It's too difficult to use for trip planning",
    'Some of the functionality I need is missing',
    "I'm using another product",
    'Other reasons',
  ],
  DOWNGRADE_HEADER: 'Why are you downgrading?',
  DOWNGRADE_REASON_OTHER_PLACEHOLDER:
    'Please specify if selected “Other reasons”',
  DOWNGRADE_CONFIRMATION_SUFFIX:
    'If you cancel your plan now, you will no longer be able to edit your other trip planning boards.',
  DOWNGRADE_CONFIRMATION_SUFFIX2:
    'You can always upgrade your plan later.',
  FREE_PLAN: 'Tourist',
  PAY_PER_TRIP: 'Adventurer (Pay-Per-Trip)',
  MONTHLY_SUBSCRIPTION: 'Globe-Trotter (Monthly Subscription)',
  ANNUAL_SUBSCRIPTION: 'Globe-Trotter (Annual Subscription)',
  PURCHASE__PLAN_TO_ADD_EDITORS: 'Please purchase plan to add more Editors!',

  SHARE_TRIP_WITH_MATES: 'Share trip',

  NEW_COMMENT_ADDED: 'Comment added',
  NEW_COMMENT_FAILED: 'Failed to add new comment',
  COMMENT_DELETED: 'Comment deleted successfully',
  COMMENT_DELETE_FAILED: 'Failed to delete comment, Please try again',

}
